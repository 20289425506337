import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <p>
        All rights reserved. Designed and created by{' '}
        <span> Bartłomiej Socha</span>
      </p>
    </div>
  );
};

export default Footer;
